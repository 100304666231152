import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import nodejs from "../../assets/images/imageIcons/nodejs.png";
import php from "../../assets/images/imageIcons/php.png";
import go from "../../assets/images/imageIcons/go.png";
import dotnet from "../../assets/images/imageIcons/dotnet.svg";
import postgres from "../../assets/images/imageIcons/postgres.png";
import python3 from "../../assets/images/imageIcons/python.png";
import aspnet from "../../assets/images/imageIcons/aspnet.png";
import bun from "../../assets/images/imageIcons/Bun.png";
import envoy from "../../assets/images/imageIcons/envoy.png";
import httpd from "../../assets/images/imageIcons/httpd.jpeg";
import java from "../../assets/images/imageIcons/java.png";
import mongo from "../../assets/images/imageIcons/mongo.svg";
import mysql from "../../assets/images/imageIcons/mysql.svg";
import nginx from "../../assets/images/imageIcons/nginx.png";
import redis from "../../assets/images/imageIcons/redis.webp";
import ruby from "../../assets/images/imageIcons/ruby.png";
import tritonserver from "../../assets/images/imageIcons/trition.png";
import prometheus from "../../assets/images/imageIcons/prometheus.png";
import pytorch from "../../assets/images/imageIcons/pytorch.png";
import podarmor from "../../assets/images/logo.svg";

const getImagePath = (name) => {
  const serviceName = name.split("/")[0];
  const imageMap = {
    nodejs: nodejs,
    "nodejs-minimal": nodejs,
    php: php,
    postgres: postgres,
    python3: python3,
    go: go,
    dotnet: dotnet,
    "dotnet-runtime": dotnet,
    "aspnet-runtime": aspnet,
    bun: bun,
    envoy: envoy,
    httpd: httpd,
    jdk: java,
    jre: java,
    mongo: mongo,
    mysql: mysql,
    nginx: nginx,
    redis: redis,
    ruby: ruby,
    tritonserver: tritonserver,
    "tritonserver-vllm-backend": tritonserver,
    "prometheus-mysqld-exporter": prometheus,
    "prometheus-pushgateway": prometheus,
    "python-fips": python3,
    "php-fips": php,
    pytorch: pytorch,
    "bun-fips": bun,
    "jdk-fips": java,
    "jre-fips": java,
    "pytorch-fips": pytorch,
    "ruby-fips": ruby,
    "podarmor-base": podarmor,
    "podarmor-minimal": podarmor,
    "prometheus": prometheus,
  };

  return imageMap[serviceName];
};

const extractName = (name) => {
  const parts = name.split("/");
  if (parts.length < 2) {
    return null;
  }

  const temp = parts[1];
  const tempParts = temp.split(":");
  return tempParts[0];
};

function Header({ name }) {

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        background: "white",
        p: 3,
        borderRadius: 4,
      }}
    >
      <Box display="flex" alignItems="center" gap={2}>
        <Box
          display="flex"
          sx={{
            alignItems: "center",
            gap: 2,
          }}
        >
          <img src={getImagePath(name)} alt="nodejs" style={{ height: 50 }} />
          <Box display={"flex"} flexDirection={"column"}>
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              {extractName(name)}
            </Typography>
            {/* if name contains FIPS display the chip */}
            {name.includes("fips") && (
              <Chip
                label="FIPS"
                sx={{
                  backgroundColor: "#5B93FF1A",
                  color: "#5B93FF!important",
                  fontWeight: "bold",
                  width: "60%",
                  "& .MuiChip-label": {
                    color: "#5B93FF!important",
                    fontWeight: "600",
                  },
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box display="flex" gap={6}>
        <Box>
          <Typography variant="body2" color="textSecondary" sx={{}}>
            Last Updated
          </Typography>
          <Typography variant="h6">3 hours ago</Typography>
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary">
            Latest Version
          </Typography>
          <Typography variant="h6">
            {extractName(name) == "nodejs" ? "22" : ""}
            {extractName(name) == "nodejs-minimal" ? "22" : ""}
            {extractName(name) == "php" ? "8.2" : ""}
            {extractName(name) == "postgres" ? "15" : ""}
            {extractName(name) == "python3" ? "3.11" : ""}
            {extractName(name) == "go" ? "1.24" : ""}
            {extractName(name) == "dotnet" ? "9.0" : ""}
            {extractName(name) == "dotnet-runtime" ? "9.0" : ""}
            {extractName(name) == "aspnet-runtime" ? "9.0" : ""}
            {extractName(name) == "bun" ? "1.2.0" : ""}
            {extractName(name) == "envoy" ? "1.33" : ""}
            {extractName(name) == "httpd" ? "2.4" : ""}
            {extractName(name) == "jdk" ? "23" : ""}
            {extractName(name) == "jre" ? "23" : ""}
            {extractName(name) == "mongo" ? "8.0" : ""}
            {extractName(name) == "mysql" ? "8.4" : ""}
            {extractName(name) == "nginx" ? "1.27.3" : ""}
            {extractName(name) == "redis" ? "7.8" : ""}
            {extractName(name) == "ruby" ? "3.4.1" : ""}
            {extractName(name) == "tritonserver" ? "2.54" : ""}
            {extractName(name) == "tritonserver-vllm-backend" ? "2.45" : ""}
            {extractName(name) == "prometheus-mysqld-exporter" ? "0.16" : ""}
            {extractName(name) == "prometheus-pushgateway" ? "1.11" : ""}
            {extractName(name) == "python-fips" ? "3.11" : ""}
            {extractName(name) == "php-fips" ? "8.2" : ""}
            {extractName(name) == "pytorch" ? "2.3.1" : ""}
            {extractName(name) == "bun-fips" ? "1.2.0" : ""}
            {extractName(name) == "jdk-fips" ? "23" : ""}
            {extractName(name) == "jre-fips" ? "23" : ""}
            {extractName(name) == "python-fips" ? "3.11" : ""}
            {extractName(name) == "pytorch-fips" ? "2.3.1" : ""}
            {extractName(name) == "ruby-fips" ? "3.4.1" : ""}
            {extractName(name) == "podarmor-base" ? "12" : ""}
            {extractName(name) == "podarmor-minimal" ? "12" : ""}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Header;
