import React, { useState, useEffect } from "react"; // Import useEffect
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import download from "../../assets/images/download.svg";
import Version from "../../assets/images/version_tag.svg";

const repositoryLookup = {
  deb: "Debian",
  npm: "NPM",
  "go-module": "Go",
  dotnet: "Dotnet",
  binary: "Binary",
};

function SBOM({ versions }) {
  const BUILD_SERVER_FILE_URL = "http://40.90.225.33:3000/files";
  const [search, setSearch] = useState("");
  const [selectedVersion, setSelectedVersion] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].version,
  );
  const [selectedArchitecture, setSelectedArchitecture] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].arch,
  );
  const [slug, _setSlug] = useState(
    versions.filter((row) => row.arch == "amd64 + arm64")[0].slug,
  );
  const [filteredVersions, setFilteredVersions] = useState([]); // Initialize as empty array
  const [displayedVersions, setDisplayedVersions] = useState([]); // New state for displayed versions

  useEffect(() => {
    const selectedVersionData = versions.find(
      (v) => v.version === selectedVersion && v.arch === selectedArchitecture,
    );
    if (selectedVersionData && Array.isArray(selectedVersionData.sbom)) {
      // keep only 1st instance of a name in the list
      const uniqueNames = new Set();
      const filteredData = selectedVersionData.sbom.filter((row) => {
        if (uniqueNames.has(row.name)) {
          return false;
        }
        uniqueNames.add(row.name);
        return true;
      });
      setFilteredVersions(filteredData);
    } else {
      setFilteredVersions([]); // Reset to empty array if no data found
    }
  }, [selectedVersion, selectedArchitecture, versions]);

  // Effect to filter displayed versions based on search input
  useEffect(() => {
    if (search.trim() === "") {
      setDisplayedVersions(filteredVersions);
    } else {
      const lowercasedSearch = search.toLowerCase();
      setDisplayedVersions(
        filteredVersions.filter((row) =>
          row.name.toLowerCase().includes(lowercasedSearch),
        ),
      );
    }
  }, [search, filteredVersions]);
  const handleDownload = async () => {
    try {
      // Ensure slug exists and is properly formatted
      if (!slug) throw new Error("Slug is not defined");
      const slugBase = slug.split(":")[0];

      // Construct URL
      const url = `${BUILD_SERVER_FILE_URL}/${slugBase}_${selectedVersion}/sbom.json`;

      // Fetch file content
      const response = await axios.get(url, { responseType: "blob" });

      // Create a URL for the blob and trigger download
      const blob = new Blob([response.data], { type: "application/json" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = `sbom_${selectedVersion}.json`;
      link.click();

      // Clean up the object URL
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error("Error downloading file:", error.message);
    }
  };
  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <div
        style={{
          display: "flex",
          gap: "1rem",
          marginBottom: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", gap: "1rem", marginBottom: "1rem" }}>
          <Select
            renderValue={(value) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <img
                  src={Version}
                  alt="version"
                  style={{ marginRight: "0.5rem" }}
                />
                <span style={{ color: "#A0AEC0" }}>Version:</span>
                <span style={{ color: "black", marginLeft: "0.3rem" }}>
                  {value}
                </span>
              </div>
            )}
            onChange={(e) => setSelectedVersion(e.target.value)}
            displayEmpty
            value={selectedVersion}
            variant="outlined"
            sx={{ borderRadius: 2, pr: 3 }}
          >
            {[
              ...new Set(
                versions
                  .filter((row) => row.arch == "amd64 + arm64")
                  .map((v) => v.version),
              ),
            ].map((version) => (
              <MenuItem key={version} value={version}>
                {version}
              </MenuItem>
            ))}
          </Select>
          <Select
            value={selectedArchitecture}
            onChange={(e) => setSelectedArchitecture(e.target.value)}
            displayEmpty
            variant="outlined"
            sx={{ borderRadius: 2, pr: 2 }}
          >
            {[
              ...new Set(
                versions
                  .filter((row) => row.arch == "amd64 + arm64")
                  .map((v) => v.arch),
              ),
            ].map((arch) => (
              <MenuItem key={arch} value={arch}>
                {arch}
              </MenuItem>
            ))}
          </Select>
          <TextField
            variant="outlined"
            placeholder="Search Versions"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ fill: "#A0AEC0" }} />
                </InputAdornment>
              ),
            }}
            sx={{
              borderRadius: 2,
              "& .MuiOutlinedInput-root": {
                borderRadius: "16px",
              },
            }}
          />
        </div>
        <Button
          variant="contained"
          onClick={handleDownload}
          sx={{
            backgroundColor: "#4FD1C5",
            color: "white",
            fontWeight: 600,
            marginBottom: 2,
            boxShadow: 0,
          }}
        >
          <img
            src={download}
            alt="download"
            style={{ marginRight: "0.5rem" }}
          />
          DOWNLOAD SBOM
        </Button>
      </div>
      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  Package
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  Version
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  border: "none",
                  backgroundColor: "#F8F9FA!important",
                  pt: 1.5,
                  pb: 1.5,
                }}
              >
                <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                  Repository
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedVersions.length > 0 ? ( // Check if displayedVersions has data
              displayedVersions.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.name}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {row.version}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                    }}
                  >
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {repositoryLookup[row.type] ?? "Binary"}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">
                  <Typography variant="body1">No data available</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default SBOM;
