import React, { useState, useEffect } from "react";
import { Container, Box } from "@mui/material";
import Header from "../components/image_details/imageHeader";
import CommandBox from "../components/image_details/commandBox";
import TabNavigation from "../components/image_details/tabNavigation";
import BreadcrumbNav from "../components/image_details/navbar";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import Sidebar from "../components/common/sideBar";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

function ImageDetails() {
  const { isAuthenticated } = useAuth0();
  const { imageId } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate("/images");
  };
  // search for param debug
  const [searchParams] = useSearchParams();
  const debug = searchParams.get("debug");
  const [versions, setVersions] = useState([]);
  const [imageNames, setImageNames] = useState("");

  const extraImages = [
    {
      id: 40,
      name: "aspnet-runtime",
      overview: null,
      slug: "aspnet-runtime/aspnet-runtime",
    },
    { id: 41, name: "bun", overview: null, slug: "bun/bun" },
    { id: 42, name: "envoy", overview: null, slug: "envoy/envoy" },
    { id: 43, name: "httpd", overview: null, slug: "httpd/httpd" },
    { id: 44, name: "jdk", overview: null, slug: "jdk/jdk" },
    { id: 45, name: "jre", overview: null, slug: "jre/jre" },
    { id: 46, name: "nginx", overview: null, slug: "nginx/nginx" },
    { id: 47, name: "php-fips", overview: null, slug: "php-fips/php-fips" },
    {
      id: 48,
      name: "prometheus-mysqld-exporter",
      overview: null,
      slug: "prometheus/prometheus-mysqld-exporter",
    },
    {
      id: 49,
      name: "prometheus-pushgateway",
      overview: null,
      slug: "prometheus/prometheus-pushgateway",
    },
    {
      id: 50,
      name: "python-fips",
      overview: null,
      slug: "python-fips/python-fips",
    },
    { id: 65, name: "pytorch", overview: null, slug: "pytorch/pytorch" },
    { id: 53, name: "ruby", overview: null, slug: "ruby/ruby" },
    {
      id: 54,
      name: "tritonserver",
      overview: null,
      slug: "tritonserver/tritonserver",
    },
    {
      id: 55,
      name: "tritonserver-vllm-backend",
      overview: null,
      slug: "tritonserver/tritonserver-vllm-backend",
    },
    { id: 56, name: "redis", overview: null, slug: "redis/redis" },
    { id: 57, name: "mongo", overview: null, slug: "mongo/mongo" },
    { id: 58, name: "mysql", overview: null, slug: "mysql/mysql" },
    { id: 59, name: "bun-fips", overview: null, slug: "bun/bun-fips" },
    { id: 60, name: "jdk-fips", overview: null, slug: "jdk/jdk-fips" },
    { id: 61, name: "jre-fips", overview: null, slug: "jre/jre-fips" },
    {
      id: 63,
      name: "pytorch-fips",
      overview: null,
      slug: "pytorch/pytorch-fips",
    },
    { id: 64, name: "ruby-fips", overview: null, slug: "ruby/ruby-fips" },
  ];

  const extraImagesNames = extraImages.map((image) => image.name);
  extraImagesNames.push("prometheus");

  const versionsList = {
    nodejs: "22",
    "nodejs-minimal": "22",
    php: "8.2",
    postgres: "15",
    python3: "3.11",
    go: "1.24",
    dotnet: "9.0",
    "dotnet-runtime": "9.0",
    "aspnet-runtime": "9.0",
    bun: "1.2.0",
    envoy: "1.33",
    httpd: "2.4",
    jdk: "23",
    jre: "23",
    mongo: "8.0",
    mysql: "8.4",
    nginx: "1.27.3",
    redis: "7.8",
    ruby: "3.4.1",
    tritonserver: "2.54",
    "tritonserver-vllm-backend": "2.45",
    "prometheus-mysqld-exporter": "0.16",
    "prometheus-pushgateway": "1.11",
    "php-fips": "8.2",
    pytorch: "2.3.1",
    "bun-fips": "1.2.0",
    "jdk-fips": "23",
    "jre-fips": "23",
    "python-fips": "3.11",
    "pytorch-fips": "2.3.1",
  };
  const extraImagesDict = Object.fromEntries(
    extraImages.map((image) => [image.id, image]),
  );

  useEffect(() => {
    axios
      .get(`/api/data/imageGroups/${imageId}`)
      .then((response) => {
        setImageNames(response.data["imageVersions"][0]["slug"]);
        setVersions(response.data["imageVersions"]);
      })
      .catch((error) => {
        setImageNames(extraImagesDict[imageId]["slug"]);
        setVersions([
          {
            version: versionsList[extraImagesDict[imageId]["name"]],
            id: imageId,
            arch: "amd64 + arm64",
          },
        ]);
        console.error("Error fetching versions:", error);
      });
  }, [imageId]); // Only fetch data when imageId changes

  return (
    <Box
      display="flex"
      flexDirection="row"
      sx={{
        background: "#F8F9FA",
        minHeight: "100vh",
        maxHeight: "100vh",
      }}
    >
      <Sidebar active={"Images"} />
      <Container
        maxWidth="lg"
        sx={{
          mt: "20px",
          ml: 0,
          maxWidth: "none !important",
        }}
      >
        <BreadcrumbNav
          title="Image Repository"
          current={imageNames ? (imageNames.split("/")[0] ?? "") : ""}
          onBack={handleBackClick}
        />
        <Box mt={3}>
          <Header name={imageNames} />
        </Box>
        {true && imageNames && !extraImagesNames.includes(imageNames.split("/")[0]) && (
          <Box display="flex" justifyContent="space-between" mt={2} gap={2}>
            <CommandBox
              text="Login to container registry"
              textSecondary="Use the following command to login to the container registry with your token"
              command="docker login -u u18321 -p ajTP7t5vI9IJR9CzYZaf71nzZqAQPXJVr+8VTxMVw0+ACRAftLH9 parmor.azurecr.io"
            />
            <CommandBox
              text="Get started with image"
              textSecondary="Use the following command to pull the image to your local machine"
              command={
                "docker pull parmor.azurecr.io/" +
                (imageNames ? (imageNames.split(":")[0] ?? "") : "") +
                ":<version>"
              }
            />
          </Box>
        )}

        <Box mt={3}>
          <TabNavigation
            versions={versions}
            flag={debug}
            image={imageNames ? (imageNames.split("/")[0] ?? "") : ""}
          />
        </Box>
      </Container>
    </Box>
  );
}

export default ImageDetails;
