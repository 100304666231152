import React from "react";
import { Card, CardContent, Typography, Box, Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import nodejs from "../../assets/images/imageIcons/nodejs.png";
import php from "../../assets/images/imageIcons/php.png";
import go from "../../assets/images/imageIcons/go.png";
import dotnet from "../../assets/images/imageIcons/dotnet.svg";
import postgres from "../../assets/images/imageIcons/postgres.png";
import python3 from "../../assets/images/imageIcons/python.png";
import aspnet from "../../assets/images/imageIcons/aspnet.png";
import bun from "../../assets/images/imageIcons/Bun.png";
import envoy from "../../assets/images/imageIcons/envoy.png";
import httpd from "../../assets/images/imageIcons/httpd.jpeg";
import java from "../../assets/images/imageIcons/java.png";
import mongo from "../../assets/images/imageIcons/mongo.svg";
import mysql from "../../assets/images/imageIcons/mysql.svg";
import nginx from "../../assets/images/imageIcons/nginx.png";
import redis from "../../assets/images/imageIcons/redis.webp";
import ruby from "../../assets/images/imageIcons/ruby.png";
import tritonserver from "../../assets/images/imageIcons/trition.png";
import prometheus from "../../assets/images/imageIcons/prometheus.png";
import pytorch from "../../assets/images/imageIcons/pytorch.png";
import podarmor from "../../assets/images/logo.svg";

const getImagePath = (name) => {
  const serviceName = name.split("/")[0];
  const imageMap = {
    nodejs: nodejs,
    "nodejs-minimal": nodejs,
    php: php,
    postgres: postgres,
    python3: python3,
    go: go,
    dotnet: dotnet,
    "dotnet-runtime": dotnet,
    "aspnet-runtime": aspnet,
    bun: bun,
    envoy: envoy,
    httpd: httpd,
    jdk: java,
    jre: java,
    mongo: mongo,
    mysql: mysql,
    nginx: nginx,
    redis: redis,
    ruby: ruby,
    tritonserver: tritonserver,
    "tritonserver-vllm-backend": tritonserver,
    "prometheus-mysqld-exporter": prometheus,
    "prometheus-pushgateway": prometheus,
    "python-fips": python3,
    "php-fips": php,
    pytorch: pytorch,
    "bun-fips": bun,
    "jdk-fips": java,
    "jre-fips": java,
    "pytorch-fips": pytorch,
    "ruby-fips": ruby,
    "podarmor-base": podarmor,
    "podarmor-minimal": podarmor,
  };

  return imageMap[serviceName];
};

const extractName = (name) => {
  if (name.split("/").length === 1) {
    return name;
  }
  return name.split("/")[1];
};

const ImageCard = ({ name, FPIS, STIG, id }) => {
  const navigate = useNavigate();
  const image = getImagePath(name);
  const handleClick = () => {
    navigate(`/image-details/${id}`);
  };

  return (
    <Card
      sx={{
        padding: 2,
        minHeight: 200,
        cursor: "pointer",
        background: "#FFF",
        boxShadow: "0px 4px 36px 0px rgba(45, 55, 72, 0.05)",
      }}
      onClick={handleClick}
    >
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Box
            component="img"
            src={image}
            alt={name}
            sx={{ height: 65, width: 65, borderRadius: 1, mr: 2 }}
          />
          <Box display="flex" flexDirection="column">
            <Typography
              variant="h6"
              fontWeight="bold"
              color="text.primary"
              gutterBottom
            >
              {extractName(name)}
            </Typography>
            <Box display="flex" justifyContent="left" gap={1}>
              {FPIS && (
                <Chip
                  label="FPIS"
                  sx={{
                    backgroundColor: "#E3EFFF",
                    color: "#4B89FF",
                    fontWeight: "bold",
                    borderRadius: 10,
                    paddingX: "6px",
                    height: "28px",
                  }}
                />
              )}
              {STIG && (
                <Chip
                  label="STIG"
                  sx={{
                    backgroundColor: "#FFECE9",
                    color: "#FF6B6B",
                    fontWeight: "bold",
                    borderRadius: 10,
                    paddingX: "6px",
                    height: "28px",
                  }}
                />
              )}
            </Box>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
              Last Updated:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              3 hours ago
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" sx={{ color: "#A0AEC0" }}>
              Latest Version:
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {extractName(name) == "nodejs" ? "22" : ""}
              {extractName(name) == "nodejs-minimal" ? "22" : ""}
              {extractName(name) == "php" ? "8.2" : ""}
              {extractName(name) == "postgres" ? "15" : ""}
              {extractName(name) == "python3" ? "3.11" : ""}
              {extractName(name) == "go" ? "1.24" : ""}
              {extractName(name) == "dotnet" ? "9.0" : ""}
              {extractName(name) == "dotnet-runtime" ? "9.0" : ""}
              {extractName(name) == "aspnet-runtime" ? "9.0" : ""}
              {extractName(name) == "bun" ? "1.2.0" : ""}
              {extractName(name) == "envoy" ? "1.33" : ""}
              {extractName(name) == "httpd" ? "2.4" : ""}
              {extractName(name) == "jdk" ? "23" : ""}
              {extractName(name) == "jre" ? "23" : ""}
              {extractName(name) == "mongo" ? "8.0" : ""}
              {extractName(name) == "mysql" ? "8.4" : ""}
              {extractName(name) == "nginx" ? "1.27.3" : ""}
              {extractName(name) == "redis" ? "7.8" : ""}
              {extractName(name) == "ruby" ? "3.4.1" : ""}
              {extractName(name) == "tritonserver" ? "2.54" : ""}
              {extractName(name) == "tritonserver-vllm-backend" ? "2.45" : ""}
              {extractName(name) == "prometheus-mysqld-exporter" ? "0.16" : ""}
              {extractName(name) == "prometheus-pushgateway" ? "1.11" : ""}
              {extractName(name) == "python-fips" ? "3.11" : ""}
              {extractName(name) == "php-fips" ? "8.2" : ""}
              {extractName(name) == "pytorch" ? "2.3.1" : ""}
              {extractName(name) == "bun-fips" ? "1.2.0" : ""}
              {extractName(name) == "jdk-fips" ? "23" : ""}
              {extractName(name) == "jre-fips" ? "23" : ""}
              {extractName(name) == "python-fips" ? "3.11" : ""}
              {extractName(name) == "pytorch-fips" ? "2.3.1" : ""}
              {extractName(name) == "ruby-fips" ? "3.4.1" : ""}
              {extractName(name) == "podarmor-base" ? "12" : ""}
              {extractName(name) == "podarmor-minimal" ? "12" : ""}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ImageCard;
