import React from "react";
import { MDXProvider } from "@mdx-js/react";
import GoDocs from "../docs/Go.mdx";
import DotnetDocs from "../docs/.NET.mdx";
import NodeDocs from "../docs/NodeJS.mdx";
import { CodeBlock } from "../docs/mdx-components";

const components = {
  code: CodeBlock,
};

const documentationLookup = {
  go: GoDocs,
  dotnet: DotnetDocs,
  nodejs: NodeDocs,
  "dotnet-runtime": DotnetDocs,
  "nodejs-minimal": NodeDocs,
};

const Documentation = ({ image }) => {
  const SelectedDoc = documentationLookup[image];

  if (!SelectedDoc) {
    return <p>Documentation for the selected type is not available.</p>;
  }

  return (
    <MDXProvider components={components}>
      <SelectedDoc />
    </MDXProvider>
  );
};

export default Documentation;
