import React, { useState } from "react";
import { Tabs, Tab } from "@mui/material";
import { ReactComponent as VersionIcon } from "../../assets/images/version.svg";
import { ReactComponent as DocumentationIcon } from "../../assets/images/Vector.svg";
// import { ReactComponent as SpecificationsIcon } from "../../assets/images/specs.svg";
import { ReactComponent as SBOMIcon } from "../../assets/images/SBOM.svg";
import { ReactComponent as VulnerabilitiesIcon } from "../../assets/images/vulnerability.svg";
import VersionTable from "./versionTable";
import Documentation from "./documentation";
import SBOM from "./SBOM";
import Vulnerabilities from "./vulnerability";

function TabNavigation({ versions, flag, image }) {
  const [value, setValue] = useState(0);

  const documentationLookup = {
    go: "https://docs.podarmor.org/Go",
    dotnet: "https://docs.podarmor.org/Dotnet",
    nodejs: "https://docs.podarmor.org/NodeJS",
    "dotnet-runtime": "https://docs.podarmor.org/Dotnet",
    "nodejs-minimal": "https://docs.podarmor.org/NodeJS",
  };

  const handleChange = (event, newValue) => {
    const url = documentationLookup[image];
    if (newValue === 1 && url) {
      // Open the documentation URL in a new tab
      window.open(url, "_blank");
    } else if (newValue != 1) {
      setValue(newValue);
    } else {
      window.open("https://docs.podarmor.org/introduction", "_blank");
    }
  };
  const tabs = [
    { label: "Versions", icon: <VersionIcon /> },
    { label: "Documentation", icon: <DocumentationIcon /> },
    // { label: "Specifications", icon: <SpecificationsIcon /> },
    { label: "SBOM", icon: <SBOMIcon /> },
    { label: "Vulnerabilities", icon: <VulnerabilitiesIcon /> },
  ];

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        TabIndicatorProps={{
          style: { display: "none" },
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.label}
            label={tab.label}
            icon={React.cloneElement(tab.icon, {
              style: {
                ...(tab.label === "Specifications"
                  ? { stroke: value === index ? "#4FD1C5" : "#A0AEC0" }
                  : { fill: value === index ? "#4FD1C5" : "#A0AEC0" }),
              },
            })}
            iconPosition="start"
            sx={{
              display: "flex",
              alignItems: "center",
              color: value === index ? "#4FD1C5" : "inherit",
              backgroundColor: value === index ? "white" : "inherit",
              "&.Mui-selected": {
                color: "#4FD1C5",
                backgroundColor: "white",
              },
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              fontWeight: 500,
              pl: 3,
              pr: 3,
              textTransform: "none",
            }}
          />
        ))}
      </Tabs>
      {value === 0 && <VersionTable versions={versions} imageName={image} />}
      {value === 1 && <Documentation image={image} />}
      {/* {value === 2 && <h1>Specifications</h1>} */}
      {value === 2 && <SBOM versions={versions} />}
      {value === 3 && <Vulnerabilities versions={versions} flag={flag} />}
    </>
  );
}

export default TabNavigation;
