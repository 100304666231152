import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Typography,
  Tooltip,
  Box,
  TextField,
  InputAdornment,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";

function VersionTable({ versions, imageName }) {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [search, setSearch] = useState("");
  const [isRequestPopupOpen, setIsRequestPopupOpen] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [messageInput, setMessageInput] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const filteredVersions = versions
    .filter((row) => row.version.includes(search) || row.url.includes(search))
    .filter((row) => row.arch === "amd64 + arm64");

  function extractDate(timestamp) {
    const date = new Date(timestamp);
    const diff = Math.floor((new Date() - date) / (1000 * 60 * 60 * 24));
    return diff === 0 ? "Today" : diff === 1 ? "Yesterday" : `${diff} days ago`;
  }

  function bytesToMB(bytes) {
    return (bytes / (1024 * 1024)).toFixed(2);
  }

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  const handleRequestClose = () => {
    setIsRequestPopupOpen(false);
    setEmailInput("");
    setMessageInput("");
    setSubmitError("");
    setSubmitSuccess(false);
  };

  const handleSubmitRequest = async () => {
    if (!emailInput.trim()) {
      setSubmitError(
        "Please provide your email to request access to the image.",
      );
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInput)) {
      setSubmitError("Please enter a valid email address.");
      return;
    }

    setIsSubmitting(true);
    setSubmitError("");

    try {
      const response = await axios.post("/api/user/request", {
        image: imageName,
        email: emailInput,
        message: messageInput,
      });

      if (response.status !== 201) {
        throw new Error("Failed to submit request");
      }

      setSubmitSuccess(true);
      setTimeout(handleRequestClose, 2000);
    } catch (err) {
      setSubmitError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Paper sx={{ padding: 3, mb: 4, boxShadow: "none" }}>
      <TextField
        label="Search"
        variant="outlined"
        placeholder="Search Versions"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <SearchIcon sx={{ fill: "#A0AEC0" }} />
            </InputAdornment>
          ),
        }}
        sx={{
          marginBottom: 3,
          borderRadius: 2,
          "& .MuiOutlinedInput-root": {
            borderRadius: "16px",
          },
        }}
      />

      <TableContainer>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {["Version", "Pull URL", "Size", "Last Updated"].map((title) => (
                <TableCell
                  key={title}
                  sx={{
                    border: "none",
                    backgroundColor: "#F8F9FA!important",
                    fontWeight: 600,
                    pt: 1.5,
                    pb: 1.5,
                  }}
                >
                  <Typography variant="body2" color="#A0AEC0" fontWeight={600}>
                    {title}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredVersions.map((row) => (
              <TableRow key={row.version}>
                <TableCell sx={{ border: "none" }}>
                  <Typography
                    variant="body1"
                    color="#4FD1C5"
                    sx={{ fontWeight: 500 }}
                  >
                    {row.version}
                  </Typography>
                </TableCell>
                <TableCell sx={{ border: "none" }}>
                  {row.url ? (
                    isAuthenticated ? (
                      <Box display="flex" alignItems="center">
                        <Tooltip title="Copy URL">
                          <IconButton
                            size="small"
                            onClick={() => handleCopy(row.url)}
                          >
                            <ContentCopyIcon
                              fontSize="small"
                              style={{ color: "#4FD1C5" }}
                            />
                          </IconButton>
                        </Tooltip>
                        <Typography
                          variant="body1"
                          noWrap
                          sx={{
                            maxWidth: 300,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            fontWeight: 500,
                          }}
                        >
                          {row.url}
                        </Typography>
                      </Box>
                    ) : (
                      <Button
                        sx={{ color: "#4FD1C5" }}
                        onClick={loginWithRedirect}
                      >
                        Login To Pull
                      </Button>
                    )
                  ) : (
                    <Button
                      sx={{ color: "#4FD1C5" }}
                      onClick={() => setIsRequestPopupOpen(true)}
                    >
                      Contact us for the Image
                    </Button>
                  )}
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  {row.size && (
                    <>
                      <Typography variant="body1" sx={{ fontWeight: 500 }}>
                        {bytesToMB(row.size)} Mb
                      </Typography>
                      <Typography variant="caption" color="#A0AEC0">
                        {row.platforms}
                      </Typography>
                    </>
                  )}
                </TableCell>

                <TableCell sx={{ border: "none" }}>
                  {row.updatedAt && (
                    <Typography variant="body1" sx={{ fontWeight: 500 }}>
                      {extractDate(row.updatedAt)}
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isRequestPopupOpen} onClose={handleRequestClose}>
        {!submitSuccess ? (
          <>
            <DialogTitle>Request Image Access</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Please provide your work email to request access to the image.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Work Email"
                type="email"
                fullWidth
                variant="standard"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
                disabled={isSubmitting}
                required
              />
              <TextField
                margin="dense"
                label="Message"
                type="text"
                fullWidth
                variant="standard"
                multiline
                rows={4}
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                disabled={isSubmitting}
              />
              {submitError && (
                <Typography color="error" variant="body2">
                  {submitError}
                </Typography>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleRequestClose}
                disabled={isSubmitting}
                sx={{
                  color: "#4FD1C5",
                  marginRight: 4,
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#4FD1C5",
                  color: "white",
                  borderRadius: 2,
                  padding: "10px 20px",
                  mr: 4,
                  fontWeight: 500,
                  boxShadow: "none",
                }}
                onClick={handleSubmitRequest}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  "Submit Request"
                )}
              </Button>
            </DialogActions>
          </>
        ) : (
          <DialogContent>
            <Typography
              color="#4FD1C5"
              variant="h6"
              sx={{
                padding: 2,
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Thanks, we will reach out to you over email with a response soon.
            </Typography>
          </DialogContent>
        )}
      </Dialog>
    </Paper>
  );
}

export default VersionTable;
