import React from "react";
import { Highlight, themes } from "prism-react-renderer";

export const Steps = ({ children }) => (
  <div style={{ margin: "20px 0", padding: "10px", border: "1px solid #ddd" }}>
    <h2>Steps to Follow</h2>
    <ol style={{ paddingLeft: "20px" }}>{children}</ol>
  </div>
);

export const Step = ({ title, children }) => (
  <li style={{ marginBottom: "10px" }}>
    <strong>{title}:</strong> {children}
  </li>
);

export const CodeBlock = ({ children, className }) => {
  const language = className ? className.replace("language-", "") : "text";

  return (
    <Highlight
      theme={themes.dracula}
      code={children.trim()}
      language={language}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <pre
          className={className}
          style={{
            ...style,
            padding: "16px",
            borderRadius: "8px",
            backgroundColor: "#282a36", // Ensure the theme background color is applied
            overflow: "auto",
            margin: "12px 0",
            fontFamily: "Consolas, monospace!important",
          }}
        >
          {tokens.map((line, i) => (
            <div key={i} {...getLineProps({ line, key: i })}>
              {line.map((token, key) => (
                <span key={key} {...getTokenProps({ token, key })} />
              ))}
            </div>
          ))}
        </pre>
      )}
    </Highlight>
  );
};
