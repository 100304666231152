import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import ImageCard from "./imageCard";
import axios from "axios";

const ImageGrid = () => {
  const [imageData, setImageData] = useState([]);
  const extraImages = [
    {
      id: 40,
      name: "aspnet-runtime",
      overview: null,
      slug: "aspnet-runtime/aspnet-runtime",
    },
    { id: 41, name: "bun", overview: null, slug: "bun/bun" },
    { id: 42, name: "envoy", overview: null, slug: "envoy/envoy" },
    { id: 43, name: "httpd", overview: null, slug: "httpd/httpd" },
    { id: 44, name: "jdk", overview: null, slug: "jdk/jdk" },
    { id: 45, name: "jre", overview: null, slug: "jre/jre" },
    { id: 46, name: "nginx", overview: null, slug: "nginx/nginx" },
    { id: 47, name: "php-fips", overview: null, slug: "php-fips/php-fips" },
    {
      id: 48,
      name: "prometheus-mysqld-exporter",
      overview: null,
      slug: "prometheus/prometheus-mysqld-exporter",
    },
    {
      id: 49,
      name: "prometheus-pushgateway",
      overview: null,
      slug: "prometheus/prometheus-pushgateway",
    },
    {
      id: 50,
      name: "python-fips",
      overview: null,
      slug: "python-fips/python-fips",
    },
    { id: 65, name: "pytorch", overview: null, slug: "pytorch/pytorch" },
    { id: 53, name: "ruby", overview: null, slug: "ruby/ruby" },
    {
      id: 54,
      name: "tritonserver",
      overview: null,
      slug: "tritonserver/tritonserver",
    },
    {
      id: 55,
      name: "tritonserver-vllm-backend",
      overview: null,
      slug: "tritonserver/tritonserver-vllm-backend",
    },
    { id: 56, name: "redis", overview: null, slug: "redis/redis" },
    { id: 57, name: "mongo", overview: null, slug: "mongo/mongo" },
    { id: 58, name: "mysql", overview: null, slug: "mysql/mysql" },

    { id: 59, name: "bun-fips", overview: null, slug: "bun/bun-fips" },
    { id: 60, name: "jdk-fips", overview: null, slug: "jdk/jdk-fips" },
    { id: 61, name: "jre-fips", overview: null, slug: "jre/jre-fips" },
    {
      id: 62,
      name: "python-fips",
      overview: null,
      slug: "python-fips/python-fips",
    },
    {
      id: 63,
      name: "pytorch-fips",
      overview: null,
      slug: "pytorch/pytorch-fips",
    },
    { id: 64, name: "ruby-fips", overview: null, slug: "ruby/ruby-fips" },
  ];

  useEffect(() => {
    axios
      .get("/api/data/imageGroups/")
      .then((response) => {
        setImageData(response.data["imageGroups"]);
        setImageData([...response.data["imageGroups"], ...extraImages]);
      })
      .catch((error) => {
        console.error("Error fetching images:", error);
      });
  }, []);
  return (
    <Grid
      container
      spacing={3}
      sx={{
        p: 3,
        overflowY: "scroll",
        maxHeight: "calc(100vh - 200px)",
      }}
    >
      {imageData.map((image, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <ImageCard {...image} />
        </Grid>
      ))}
    </Grid>
  );
};

export default ImageGrid;
